<template>
    <section v-if="initialize" class="my-0" :class="{'section': desktop}">
        <card class="border-0">
            <div class="row justify-content-center">
                <div class="col-lg-4">
                    <card type="secondary" shadow
                          header-classes="bg-white pb-5"
                          body-classes="px-lg-5 py-lg-5"
                          class="border-0">
                        <template>
                            <base-alert v-if="alert" :type="alert_type">
                                <span class="alert-inner--text">{{alert_message}}</span>
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="alert = false">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </base-alert>
                            <form role="form">
                                <b-form-group>
                                    <b-form-radio-group v-model="model.as">
                                        <b-form-radio value="requestor">{{$t('requestor')}}</b-form-radio>
                                        <b-form-radio value="realtor">{{$t('realtor')}}</b-form-radio>
                                    </b-form-radio-group>
                                </b-form-group>

                                <base-input alternative
                                            v-model="model.email"
                                            placeholder="E-mail"
                                            addon-left-icon="ni ni-email-83">
                                </base-input>
                                
                                <div class="text-center">
                                    <base-button type="primary" class="my-3" @click="submitModel()">{{$t('reset.submit')}}</base-button>
                                </div>
                            </form>
                        </template>
                    </card>
                    <div class="row mt-3">
                        <div class="col-6" style="font-weight: bold !important">
                            <router-link to="/login" class="text-light">
                                {{$t('reset.login')}}
                            </router-link>
                        </div>
                        <div class="col-6" style="font-weight: bold !important">
                            <router-link to="/register" class="text-light">
                                {{$t('reset.register')}}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </card>
    </section>
</template>
<script>
    import { BFormGroup, BFormRadio, BFormRadioGroup } from 'bootstrap-vue';
    import $ from 'jquery';

    export default {
        components: {
            BFormRadio,
            BFormGroup,
            BFormRadioGroup,
        },

        data() {
            return {
                model: {
                    as: 'requestor',
                    email: '',
                },
                alert: false,
                alert_message: '',
                alert_type: 'warning',

                initialize: false,
                desktop: screen.width > 700,
            }
        },

        methods: {
            submitModel() {
                var message;
                var alert;
                var pushAlert = this.pushAlert;

                if (this.model.email === '') {
                    alert = true;
                    message = this.$t('warning.reset.email');
                } else if (!(this.model.email.includes('@') && this.model.email.includes('.') && this.model.email.length > 5)) {
                    alert = true;
                    message = this.$t('warning.reset.email');
                }

                if (alert) {
                    pushAlert(alert, message);
                } else {
                    $.ajax({
                        url: 'https://api.immobl.com/reset',
                        contentType: 'application/json;charset=UTF-8',
                        type: 'POST',
                        xhrFields: {
                            withCredentials: true
                        },
                        crossDomain: true,
                        data: JSON.stringify({ data: this.model }),
                        success: function (response) {
                            if (response.success) {
                                pushAlert(true, 'warning.reset.success', 'success');
                            } else {
                                pushAlert(true, 'warning.reset.match', 'warning');
                            }
                        },
                        //error: function (err) {
                        //    //console.log(err);
                        //},
                    });
                }
            },

            pushAlert(alert, message, alert_type) {
                if (typeof alert_type != 'undefined') {
                    this.alert_type = alert_type;
                }

                var as = this.$t('warning.login.as.' + this.model.as);

                this.alert = alert;
                this.alert_message = this.$t(message, {as: as});
                $("html, body").animate({ scrollTop: 0 }, 1000);
            }
        },

        mounted() {
            if (this.$store.state.user_logged) {
                this.$router.push('dashboard');
            } else {
                this.initialize = true;
            }
        }
    };
</script>
<style>
</style>
